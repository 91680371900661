import React, { useState } from "react";
import { Row, Button, Table, DatePicker, Space, message, Spin } from "antd";
import { LightBlueContainer, WhiteContainer } from "../../styled/Container.styled";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import pageSize from "../../../constants/pageSize";
import { useGetAllTransactionsForMonthQuery } from "../../../feature/Transactions/transactionApiSlice";
import { ViewDetailsButton } from "../../styled/Button.styled";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

export default function Transaction() {
  const [selectedMonth, setSelectedMonth] = useState<string>(getCurrentMonthYear());
  const [isDownloading, setIsDownloading] = useState<boolean>(false); // State to track download status
  const { data, isLoading: isTransactionsLoading, refetch: fetchTransactions } = useGetAllTransactionsForMonthQuery(selectedMonth);
  const navigate = useNavigate();

  const openPlaceDetails = (record: any) => {
    navigate(`view/${record.tripId}`, { state: { transaction: record } });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "driverName",
      key: "driverName",
    },
    {
      title: "Payment ID",
      dataIndex: "paymentId",
      key: "paymentId",
    },
    {
      title: "Amount",
      dataIndex: "transactionAmount",
      key: "transactionAmount",
    },
    {
      title: "Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Trip Type",
      dataIndex: "tripType",
      key: "tripType",
    },
    {
      title: "Transaction Date",
      dataIndex: "dateOfTransaction",
      key: "dateOfTransaction",
    },
    {
      key: "viewDetails",
      dataIndex: "viewDetails",
      render: (_, record) => (
        <ViewDetailsButton onClick={() => openPlaceDetails(record)}>
          View Details
        </ViewDetailsButton>
      ),
    },
  ];

  const handleMonthChange = (date: any) => {
    if (date) {
      const selectedDate = date.format("YYYY-MM");
      setSelectedMonth(selectedDate);
      fetchTransactions();
    }
  };

const handleDownloadButtonClick = async () => {
  setIsDownloading(true);
  try {
      const apiUrl = `https://himcab.prod.service.ripple-anubudh.tech/api/admin/transaction/download-invoices?month=${selectedMonth}`;
      const headerToken = secureLocalStorage.getItem("token");
      if (!headerToken) {
          message.error("Authorization token is missing.");
          setIsDownloading(false);
          return;
      }

      const response = await axios.get(apiUrl, {
          headers: {
              Authorization: `Bearer ${headerToken}`,
          },
          responseType: "blob", 
      });

      if (response.status === 200) {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'download.zip'; 

          if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
              if (fileNameMatch != null && fileNameMatch[1]) {
                  fileName = fileNameMatch[1].replace(/['"]/g, '');
                  if (!fileName.endsWith('.zip')) {
                      fileName += '.zip'; 
                  }
              }
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); 
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          message.success("Invoices are downloading.");
      } else {
          throw new Error("Failed to download invoices.");
      }
  } catch (error) {
      message.error("Failed to download invoices.");
      console.error("Failed to download invoices:", error);
  }
  setIsDownloading(false); 
};

  const handleDownloadExcelClick = async () => {
    setIsDownloading(true); 
    try {
      const excelApiUrl = `https://himcab.prod.service.ripple-anubudh.tech/api/admin/transaction/download-invoices-excel?month=${selectedMonth}`;
      const headerToken = secureLocalStorage.getItem("token");
      if (!headerToken) {
        message.error("Authorization token is missing.");
        setIsDownloading(false);
        return;
      }

      const response = await axios.get(excelApiUrl, {
        headers: {
          Authorization: `Bearer ${headerToken}`,
        },
        responseType: "blob",
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report_${selectedMonth}.xlsx`); 
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
        message.success("Excel file is downloading.");
      } else {
        throw new Error("Failed to download Excel file.");
      }
    } catch (error) {
      message.error("Failed to download Excel file.");
      console.error("Failed to download Excel file:", error);
    }
    setIsDownloading(false); 
  };

  return (
    <>
      <LightBlueContainer>
        <Row justify="end" className="button-bottom-margin">
          <Space>
            <DatePicker
              picker="month"
              format="YYYY-MM"
              onChange={handleMonthChange}
              defaultValue={dayjs(selectedMonth, "YYYY-MM")}
            />
            <Button
              type="primary"
              onClick={handleDownloadButtonClick}
              style={{ background: "#3e4685", borderColor: "#1890ff" }}
              disabled={isDownloading} 
            >
              Download Invoice
            </Button>
            <Button
              type="primary"
              onClick={handleDownloadExcelClick}
              style={{ background: "#3e4685", borderColor: "#1890ff" }}
              disabled={isDownloading}
            >
              Download Excel Report
            </Button>
          </Space>
        </Row>
        <WhiteContainer>
          {isDownloading ? ( 
            <Spin tip="Downloading...">
              <Table<any>
                loading={isTransactionsLoading}
                columns={columns}
                dataSource={data?.data.content.map((item: any) => ({ ...item, key: item.transactionId }))}
                pagination={{ pageSize }}
              />
            </Spin>
          ) : (
            <Table<any>
              loading={isTransactionsLoading}
              columns={columns}
              dataSource={data?.data.content.map((item: any) => ({ ...item, key: item.transactionId }))}
              pagination={{ pageSize }}
            />
          )}
        </WhiteContainer>
      </LightBlueContainer>
    </>
  );
}

function getCurrentMonthYear() {
  const date = new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  return `${year}-${month}`;
}
